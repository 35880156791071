import { select, put, takeLatest } from 'redux-saga/effects';

import { LOAD, loaded, Action } from '../reducers/billing';
import { authenticatedRequest } from '../../utils';

import { State } from '../../types';
import { billingPaymentsUrl } from '../../routes';

function* _load(action: Action) {
	const state: State = yield select();

	try {
		const response = yield authenticatedRequest(billingPaymentsUrl, state.auth.token || '');
		yield put(loaded(response.data));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
}
