import { iFormGeneric, PaginatedFilteredSorted, PaginationPayload } from '../../types';

export const resultsPerPage = 20;

export interface iModel {
	id: number;
	rule: string;
	category: number;
	connections: number[];
	age_min: number;
}

export interface iData extends PaginatedFilteredSorted {
	results: iModel[];
}

export interface iForm extends iFormGeneric {
	data: {
		rule: string;
		category?: number;
		connections?: number[];
		age_min: number;
	};
}

export interface iState {
	data: iData;
	form: iForm;
}

export interface iPayload {
	data: iModel;
	form: iForm;
	pagination: PaginationPayload;
}

export const initialState: iState = {
	data: {
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loading   : false,
		filters   : [],
		sorters   : [],
		pageSize  : resultsPerPage,
		pageNumber: 1,
	},
	form: {
		errors    : {},
		data      : {
			rule   : '',
			age_min: 0,
		},
		submitting: false,
	},
};
