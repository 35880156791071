import { Pagination, PaginationPayload } from '../../types';

export const resultsPerPage = 50;

export const TYPE_BLOCK: number = 0;
export const TYPE_ALLOW: number = 1;

export interface iModel {
	id: number;
	name: string;
	identifier: string;
	color: string;
	type: number;
	type_str: string;
	description: string;
	age_min: number;
}

export interface iData extends Pagination {
	results: iModel[];
}

export interface iState {
	data: iData;
}

export interface iPayload {
	data: iData;
	pagination: PaginationPayload;
}

export const initialState: iState = {
	data: {
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loading   : false,
		pageSize  : resultsPerPage,
		pageNumber: 1,
	},
};
