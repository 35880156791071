export const LOAD = 'DEVICE_STATS/LOAD';
export const LOADED = 'DEVICE_STATS/LOADED';
export const SET_INTERVAL = 'DEVICE_STATS/SET_INTERVAL';

export interface iAction {
	type: string;
	payload: iStat[] | number | string;
}

export interface iStat {
	allowed: number;
	blocked: number;
	timestamp: string;
}

export interface iState {
	results: iStat[];
	loaded: boolean;
	loading: boolean;
	interval: string;
}

const initialState: iState = {
	loaded  : false,
	loading : false,
	results : [],
	interval: '1-W',
};

export const load = () => ({ type: LOAD });
export const loaded = (results: iStat[]) => ({ type: LOADED, payload: results });
export const setInterval = (interval: string) => ({ type: SET_INTERVAL, payload: interval });

export default (state = initialState, action: iAction) => {
	const { type, payload } = action;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					loading: true,
				},
			};
		case LOADED:
			return {
				...state, ...{
					loaded : true,
					loading: false,
					results: <iStat[]>payload,
				},
			};
		case SET_INTERVAL:
			return {
				...state, ...{
					interval: <string>payload,
				},
			};
		default:
			return state;
	}
};

