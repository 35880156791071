export interface iModel {
	id: number;
	fqdn: string;
	ipv4: string;
	ipv6: string;
	hostname: string;
	loc_city: string;
	loc_state: string;
	loc_country_code: string;
}

export interface iState {
	servers: iModel[];
	loading: boolean;
}

export interface iPayload {
	servers: iModel[];
}

export const initialState: iState = {
	servers: [],
	loading: false,
};
