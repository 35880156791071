import { select, put, takeLatest } from 'redux-saga/effects';

import { LOAD, loaded, iAction } from '@/state/reducers/stats/current_usage';
import { authenticatedRequest } from '@/utils';

import { State } from '@/types';
import { statsCurrentUsage } from '@/routes';

function* _load(action: iAction) {
	const state: State = yield select();
	try {
		const response = yield authenticatedRequest(statsCurrentUsage, state.auth.token || '');
		yield put(loaded(response));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
}
