import { select, put, takeLatest } from 'redux-saga/effects';

import { template } from 'lodash';
import { LOAD, SET_INTERVAL, loaded, iAction } from '../reducers/device_stats';
import { authenticatedRequest, SerializeParam, serialize } from '@/utils';

import { State } from '@/types';
import { deviceTimelineStatsUrl } from '@/routes';

function* _load(action: iAction) {
	const state: State = yield select();
	if (!state.devices.selected) return;

	const queryObj: SerializeParam[] = [{
		key  : 'interval',
		value: state.device_stats.interval,
	}];

	try {
		const url = `${template(deviceTimelineStatsUrl)(
			{ device_id: state.devices.selected.id },
		)}?${serialize(queryObj)}`;

		const response = yield authenticatedRequest(url, state.auth.token || '');
		yield put(loaded(response.data));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
	yield takeLatest(SET_INTERVAL, _load);
}
