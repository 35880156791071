import { iFormGeneric, iModelGeneric } from '@/types';

export interface iModelUserMeta {
	requests_capped: boolean;
	requests_denied: boolean;
	allow_count: number;
	block_count: number;
}

export interface iModel extends iModelGeneric {
	user: number;
	email: string;
	token: string;
	meta: iModelUserMeta;
	username: string;
	logging_level: number;
	new_password?: string;
	new_password_re?: string;
	current_password?: string;

	//	subscription details
	is_subscribed?: boolean;
	has_active_subscription?: boolean; // (see backend for difference between these two)

	can_create_connections?: boolean;
	subscription_ends?: number;
	subscription_plan?: number;
	subscription_cycle?: number;
	connections_limit?: number;
}

export interface iForm extends iFormGeneric {
	data: {
		email: string;
		username: string;
		new_password: string;
		new_password_re: string;
		current_password: string;
	};
}

export interface iState {
	loaded: boolean;
	data: iModel;
	form: iForm;
}

export interface iPayload {
	data: iModel;
	form: iForm;
}

export const initialState: iState = {
	loaded: false,
	data  : {
		meta                   : {
			allow_count    : 0,
			block_count    : 0,
			requests_capped: false,
			requests_denied: false,
		},
		user                   : 0,
		email                  : '',
		token                  : '',
		username               : '',
		loading                : false,
		logging_level          : 0,
		has_active_subscription: false,
		can_create_connections : false,
		subscription_ends      : 0,
		subscription_plan      : 0,
		connections_limit      : 0,
	},
	form  : {
		errors    : {},
		data      : {
			email           : '',
			username        : '',
			new_password    : '',
			new_password_re : '',
			current_password: '',
		},
		submitting: false,
	},
};
