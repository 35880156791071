import { iFormValidationError } from '../../types';

export interface iValidationErrors extends iFormValidationError {
	email?: string[];
	username?: string[];
	password?: string[];
	password_re?: string[];
}

export interface iState {
	formsubmitting: boolean;
	validationErrors: iValidationErrors;
}

export interface iPayload {
	email: string;
	username: string;
	password: string;
	password_re: string;
	validation: iValidationErrors;
}

export const initialState: iState = {
	formsubmitting  : false,
	validationErrors: {},
};
