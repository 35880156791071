import { iModel as iServerModel } from '@/state/models/servers';
import { iFormValidationError, PaginatedFilteredSorted, PaginationPayload } from '@/types';

export const connectionsPerPage = 20;

export interface RouteTargetModel {
	[key: string]: any;

	target_id: number;
	mo: [number, number];
	tu: [number, number];
	we: [number, number];
	th: [number, number];
	fr: [number, number];
	sa: [number, number];
	su: [number, number];
}

export interface Model {
	[key: string]: any;

	id: number;
	alias: string;
	identifier: string;
	ip_address: string;
	dynamicdns_hostname: string;
	safe_search: boolean;
	lockdown_mode: boolean;
	rules_categories: number[];
	age_restrict: boolean;
	age_min: number;
	server?: iServerModel;
	route_target?: RouteTargetModel;
}

export interface Data extends PaginatedFilteredSorted {
	loaded: boolean;
	results: Model[];
}

export interface ValidationErrors extends iFormValidationError {
	[key: string]: any;

	alias?: string[];
	location?: string[];
	ip_address?: string[];
	safe_search?: string[];
	lockdown_mode?: string[];
	rules_categories?: string[];
	age_restrict?: string[];
	age_min?: string[];
}

export interface RouteValidationErrors extends iFormValidationError {
	[key: string]: any;

	target_id?: string[];
	mo?: string[];
	tu?: string[];
	we?: string[];
	th?: string[];
	fr?: string[];
	sa?: string[];
	su?: string[];
}

export interface State {
	data: Data;

	validationErrors: ValidationErrors;
	routeValidationErrors: RouteValidationErrors;
	//	the id of the connection to be auto updated (dynamic ip)
	autoUpdateConnection?: number;
	//	which connection you want expanded in the table. if any..
	expandedConnection?: number;
}

export interface Payload {
	data: Model;
	validationErrors: ValidationErrors;
	routeValidationErrors: RouteValidationErrors;
	pagination: PaginationPayload;
}

export const initialState: State = {
	data                 : {
		loaded    : false,
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loading   : false,
		filters   : [],
		sorters   : [],
		pageSize  : connectionsPerPage,
		pageNumber: 1,
	},
	validationErrors     : {},
	routeValidationErrors: {},
};
