import { iModel as iPlanModel } from './plans';
import { PaginatedFilteredSorted, PaginationPayload } from '../../types';

export const resultsPerPage = 20;

export interface iModel {
	id: number;
	status: string;
	amount: number;
	created: number;
	transaction_id: string;
	plan: iPlanModel;
}

export interface iData extends PaginatedFilteredSorted {
	results: iModel[];
}

export interface iState {
	data: iData;
}

export interface iPayload {
	data: iData;
	pagination: PaginationPayload;
}

export const initialState: iState = {
	data: {
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loading   : false,
		filters   : [],
		sorters   : [],
		pageSize  : resultsPerPage,
		pageNumber: 1,
	},
};
