import { combineReducers } from 'redux';
import { responsiveStateReducer } from 'redux-responsive';

import auth from './auth';
import profile from './profile';
import plans from './plans';
import connections from './connections';
import rule from './rule';
import rules from './rules';
import device_stats from './device_stats';
import connection_stats from './connection_stats';
import billing from './billing';
import register from './register';
import servers from './servers';
import payment from './payment';
import logs from './logs';
import devices from './devices';
import rule_categories from './rule_categories';
import stats_current_usage from './stats/current_usage';
import stats_protocols from './stats/protocols';
import stats_connections from './stats/connections';
import stats_rule_categories from './stats/rule_categories';

export default combineReducers({
	browser: responsiveStateReducer,
	auth,
	logs,
	plans,
	devices,
	payment,
	profile,
	servers,
	billing,
	rule_categories,
	connections,
	rule,
	rules,
	device_stats,
	connection_stats,
	register,
	stats_protocols,
	stats_connections,
	stats_current_usage,
	stats_rule_categories,
});
