import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import createStore from '@/state/create-store';

// don't change this unless you verify that the build pages
// are exporting the title and meta elements
export default ({ element }: any) => {
	const store = createStore();

	if (typeof window === 'undefined') {
		return (
			<Provider store={store.store}>
				{element}
			</Provider>
		);
	}
	return (
		<Provider store={store.store}>
			<PersistGate loading={null} persistor={store.persistor}>
				{element}
			</PersistGate>
		</Provider>
	);
};
