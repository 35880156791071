import { select, put, call, takeLatest } from 'redux-saga/effects';

import { message } from 'antd';

import {} from '../reducers/rules';
import { authenticatedRequest, SerializeParam, serialize } from '../../utils';

import { Action } from '../reducers/rules';
import { userRulesUrl, userRulesWhitelistedUrl } from '../../routes';
import { iFormValidationError, State } from '../../types';
import { TYPE_ALLOW } from '../models/rule_categories';
import {
	formSubmitting,
	LOAD,
	PAGINATE,
	WHITELIST,
	CREATE,
	DELETE,
	loaded,
	load,
	formReset,
	formValidationError,
} from '../reducers/rules';

function* _load(action: Action) {
	const state: State = yield select();

	const queryObj: SerializeParam[] = [{
		key  : 'page',
		value: state.rules.data.pageNumber,
	}, {
		key  : 'page_size',
		value: state.rules.data.pageSize,
	}];

	if (state.rules.data.filters) {
		for (let i = 0; i < state.rules.data.filters.length; i++) {
			const filter = state.rules.data.filters[i];
			queryObj.push({
				key  : filter.column,
				value: filter.query,
			});
		}
	}

	if (state.rules.data.sorters) {
		for (let i = 0; i < state.rules.data.sorters.length; i++) {
			const sort = state.rules.data.sorters[i];
			queryObj.push({
				key  : 'ordering',
				value: `${sort.order == 'descend' ? '-' : ''}${sort.column}`,
			});
		}
	}

	try {
		const response = yield authenticatedRequest(`${userRulesUrl}?${serialize(queryObj)}`,
			state.auth.token || '');
		yield put(loaded(response.data));
	} catch (e) {
		console.error(e.message);
	}
}

function* _create(action: Action) {
	const state: State = yield select();

	try {
		yield put(formSubmitting());

		const response = yield authenticatedRequest(userRulesUrl, state.auth.token || '', 'POST', {
			...action.payload.data,
		});

		if (response.data.validation) {
			const fields: any = Object.keys(response.data.validation);

			const vError: iFormValidationError = {};
			for (let i = 0; i < fields.length; i++) {
				vError[fields[i]] = response.data.validation[fields[i]];
			}

			yield put(formValidationError(vError));
		} else {
			yield put(load());
			yield put(formReset());
		}
	} catch (e) {
		yield put(formReset());
		yield put(load());

		console.error(e.message);
	}

}

function* remove(action: Action) {
	const state: State = yield select();

	try {
		yield authenticatedRequest(
			userRulesUrl + `${action.payload.data.id}`,
			state.auth.token || '',
			'DELETE',
		);

		yield put(load());
	} catch (e) {
		console.error(e);
	}
}


function* whitelist(action: Action) {
	const state: State = yield select();
	const allowedCategory = state.rule_categories.data.results.filter(
		(c) => c.type == TYPE_ALLOW,
	)[0];

	const response = yield authenticatedRequest(userRulesUrl,
		state.auth.token || '',
		'POST',
		{
			rule    : action.payload.data.rule,
			category: allowedCategory.id,
		});

	if (!response.data.validation || response.data.validation['non_field_errors'].length) {
		message.success(`${action.payload.data.rule} was whitelisted`);
	} else {
		message.error(`I was unable to whitelist. This is an app error`);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
	yield takeLatest(PAGINATE, _load);
}

export function* createSaga(storeAPI: any) {
	yield takeLatest(CREATE, _create);
}

export function* whitelistSaga(storeAPI: any) {
	yield takeLatest(WHITELIST, whitelist);
}

export function* removeSaga(storeAPI: any) {
	yield takeLatest(DELETE, remove);
}
