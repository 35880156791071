import { initialState, iState, iModel, iPayload } from '../models/servers';

export const LOAD = 'SERVERS/LOAD';
export const LOADED = 'SERVERS/LOADED';


export interface Action {
	type: string;
	payload: iPayload;
}

export const load = () => (
	{ type: LOAD }
);

export const loaded = (servers: iModel[]) => (
	{ type: LOADED, payload: { servers } }
);

export default (state = initialState, action: Action): iState => {
	const { type, payload } = action;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					loading: true,
				},
			};
		case LOADED:
			return {
				...state, ...{
					loading: false,
					servers: payload.servers,
				},
			};
		default:
			return state;
	}
};

