import { iData, initialState, iPayload, iModel } from '../models/rules';
import { PaginationPayload } from '../../types';


export const LOAD = 'RULES/LOAD';
export const LOADED = 'RULES/LOADED';
export const PAGINATE = 'RULES/PAGINATE';
export const FORM_RESET = 'RULES/FORM_RESET';
export const FORM_CHANGE = 'RULES/USER/BLOCKED/FORM_CHANGE';
export const FORM_SUBMITTING = 'RULES/USER/BLOCKED/FORM_SUBMITTING';
export const FORM_VALIDATION_ERROR = 'RULES/FORM_VALIDATION_ERROR';
export const CREATE = 'RULES/CREATE';
export const WHITELIST = 'RULES/WHITELIST';
export const DELETE = 'RULES/DELETE';

export interface Action {
	type: string;
	payload: iPayload;
}

export const load = () => ({ type: LOAD });

export const create = (data: iModel) => ({
	type: CREATE, payload: {
		data,
	},
});

export const remove = (id: number) => ({
	type: DELETE, payload: {
		data: {
			id,
		},
	},
});

export const paginate = (params: PaginationPayload) => ({
	type: PAGINATE, payload: {
		pagination: { ...params },
	},
});

export const loaded = (data: iData) => ({
	type: LOADED, payload: {
		data,
	},
});

export const whitelist = (data: iModel) => ({
	type: WHITELIST, payload: {
		data,
	},
});

export const formSubmitting = () => (
	{ type: FORM_SUBMITTING }
);

export const formReset = () => (
	{ type: FORM_RESET }
);

export const formChange = (data: object) => (
	{ type: FORM_CHANGE, payload: { data } }
);

export const formValidationError = (errors: object) => (
	{ type: FORM_VALIDATION_ERROR, payload: { form: { errors } } }
);


export default (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case PAGINATE:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading   : true,
						filters   : action.payload.pagination.filters,
						sorters   : action.payload.pagination.sorters,
						pageNumber: action.payload.pagination.pageNumber,
					}),
				},
			};
		case LOAD:
			return {
				...state, ...{
					data: Object.assign({
						loading: true,
					}, state.data),
				},
			};
		case LOADED:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: false,
					}, payload.data),
				},
			};
		case CREATE:
			return {
				...state, ...{
					validationErrors: {},
				},
			};
		case FORM_SUBMITTING:
			return {
				...state, ...{
					formsubmitting: true,
				},
			};
		case FORM_CHANGE:
			return {
				...state, ...{
					form: Object.assign({}, state.form, {
						data: Object.assign({}, state.form.data, payload.data),
					}),
				},
			};
		case FORM_RESET:
			return {
				...state, ...{
					form: initialState.form,
				},
			};
		case FORM_VALIDATION_ERROR:
			return {
				...state, ...{
					formsubmitting: false,
					data          : Object.assign({
						loading: false,
					}, state.data),
					form          : Object.assign({}, state.form, {
						errors: Object.assign({}, state.form.errors, payload.form.errors),
					}),
				},
			};

		default:
			return state;
	}
};
