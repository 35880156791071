import { initialState, iState, iPayload, iValidationErrors } from '../models/register';

export const REGISTER = 'REGISTER/REGISTER';
export const REGISTERED = 'REGISTER/REGISTERED';
export const FORM_VALIDATION_ERROR = 'REGISTER/VALIDATION_ERROR';


export interface Action {
	type: string;
	payload: iPayload;
}


export const register = (
	email: string,
	username: string,
	password: string,
	password_re: string,
) => (
	{ type: REGISTER, payload: { email, username, password, password_re } }
);

export const registered = () => (
	{ type: REGISTERED }
);

export const formValidationErrors = (validation: iValidationErrors) => (
	{ type: FORM_VALIDATION_ERROR, payload: { validation } }
);

export default (state = initialState, action: Action): iState => {
	const { type, payload } = action;
	switch (type) {
		case REGISTER:
			return {
				...state, ...{
					formsubmitting: true,
				},
			};
		case FORM_VALIDATION_ERROR:
			return {
				...state, ...{
					formsubmitting  : false,
					validationErrors: payload.validation,
				},
			};
		default:
			return state;
	}
};

