import { navigate } from 'gatsby';
import { select, call, put, takeLatest } from 'redux-saga/effects';

import { message, Modal } from 'antd';

import { SHOW, CANCEL_CURRENT_SUBSCRIPTION, Action } from '../reducers/payment';
import { iModel as iPlanModel } from '../models/plans';
import { load as loadProfile } from '../reducers/profile';
import { State } from '../../types';
import PaddleConfig from '../../paddle';
import { authenticatedRequest } from '../../utils';
import { subscriptionsUrl } from '../../routes';

function showPayment(email: string, plan: iPlanModel, cycle: 'monthly' | 'bi-annually' | 'annually') {
	window.Paddle.Setup({ vendor: PaddleConfig.vendorId });

	window.Paddle.Checkout.open({
		// @ts-ignore
		product        : PaddleConfig.products[plan.name.toLowerCase()][cycle],
		email          : email,
		successCallback: (data: any) => {
			//	don't show this if the product has a trial
			Modal.success({
				title  : 'Thank you!',
				content: `Please allow a few minutes for a response from 
				the payment gateway `,
			});

			loadProfile();

			navigate('/app/connections');
		},
		closeCallback  : (data: any) => {
			message.warn('Your purchase was cancelled');
			loadProfile();
			navigate('/app/profile');
		},
	});
}

function* __show(action: Action) {
	const state: State = yield select();
	const profile = state.profile;

	try {
		if (profile.data.email) {
			//	if there is an active subscription we have to prompt
			//	for it to be cancelled first
			if (profile.data.has_active_subscription) {
				const cancel = yield function() {
					return new Promise((resolve) => {
						Modal.confirm({
							title   : 'Cancel current subscription?',
							content : `You seem to be having an active subscription. Before switching plans we need to cancel 
					that one and proceed with the new plan. Should we cancel?`,
							onOk    : () => resolve(true),
							onCancel: () => resolve(false),
						});
					});
				}();

				if (cancel === true) {
					yield call(__cancelCurrentSubscription, action);
					showPayment(profile.data.email, action.payload.plan, action.payload.cycle);
				}
			} else {
				showPayment(profile.data.email, action.payload.plan, action.payload.cycle);
			}
		}
	} catch (e) {
		console.error(e);
	}
}

function* __cancelCurrentSubscription(action: Action) {
	const state: State = yield select();

	try {
		yield authenticatedRequest(
			subscriptionsUrl,
			state.auth.token || '',
			'DELETE',
		);

		yield put(loadProfile());
	} catch (e) {
		console.error(e);
	}
}

export function* showSaga(storeAPI: any) {
	yield takeLatest(SHOW, __show);
}

export function* cancelCurrentSubscriptionSaga(storeAPI: any) {
	yield takeLatest(CANCEL_CURRENT_SUBSCRIPTION, __cancelCurrentSubscription);
}
