import { iFormValidationError } from '../../types';

export interface iValidationErrors extends iFormValidationError {
	username?: string[];
	password?: string[];
}

export interface iState {
	token: string;
	formsubmitting: boolean;
	validationErrors: iValidationErrors;
}

export interface iPayload {
	token: string;
	username: string;
	password: string;
	redirectToDashboard: boolean;
	validation: iValidationErrors;
}

export const initialState: iState = {
	token           : '',
	formsubmitting  : false,
	validationErrors: {},
};
