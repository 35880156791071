import { select, put, takeLatest } from 'redux-saga/effects';

import { message } from 'antd';

import {
	Action,
	LOAD,
	PAGINATE,
	UPDATE,
	CREATE,
	DELETE,
	load,
	loading,
	formReset,
	formModalHide,
	formValidationError,
	setExpanded,
	routeFormValidationError,
	loaded, SET_ROUTE_TARGET,
} from '@/state/reducers/connections';
import { authenticatedRequest, serialize, SerializeParam } from '@/utils';

import { State } from '@/types';
import { connectionRouteUrl, connectionsUrl, deviceTimelineStatsUrl } from '@/routes';
import { Payload, RouteTargetModel } from '@/state/models/connection';
import { getExpanded } from '@/state/selectors/connections';
import { template } from 'lodash';

function* list(action: Action) {
	const state: State = yield select();

	const queryObj: SerializeParam[] = [{
		key  : 'page',
		value: state.connections.data.pageNumber,
	}, {
		key  : 'page_size',
		value: state.connections.data.pageSize,
	}];

	if (state.connections.data.filters) {
		for (let i = 0; i < state.connections.data.filters.length; i++) {
			const filter = state.connections.data.filters[i];
			queryObj.push({
				key  : filter.column,
				value: filter.query,
			});
		}
	}

	if (state.connections.data.sorters) {
		for (let i = 0; i < state.connections.data.sorters.length; i++) {
			const sort = state.connections.data.sorters[i];
			queryObj.push({
				key  : 'ordering',
				value: `${sort.order == 'descend' ? '-' : ''}${sort.column}`,
			});
		}
	}

	try {
		const response = yield authenticatedRequest(`${connectionsUrl}?${serialize(queryObj)}`,
			state.auth.token || '');
		yield put(loaded(response.data));
	} catch (e) {
		console.error(e.message);
	}
}

function* update(action: Action) {
	yield put(loading());
	const state: State = yield select();
	const { data } = <Payload>action.payload;
	try {
		const response = yield authenticatedRequest(
			connectionsUrl + `${data.id}/`,
			state.auth.token || '',
			'PUT',
			{ ...data },
		);

		if (response.data.validation) {
			message.error(response.data.validation[Object.keys(response.data.validation)[0]], 5);
		} else {
			yield put(formReset());
		}

		yield put(load());
	} catch (e) {
		console.error(e.message);
	}
}

function* setRouteTarget(action: Action) {
	yield put(loading());
	const state: State = yield select();
	const data = <RouteTargetModel>action.payload;
	const source = getExpanded(state);
	try {
		const response = yield authenticatedRequest(
			template(connectionRouteUrl)({ connection_id: source.id }),
			state.auth.token || '',
			'PUT',
			{ ...data },
		);

		if (response.data.validation) {
			yield put(routeFormValidationError(response.data.validation));
		} else {
			yield put(formReset());
		}

		yield put(load());
	} catch (e) {
		console.error(e.message);
	}
}

function* create(action: Action) {
	const state: State = yield select();

	try {
		const response = yield authenticatedRequest(
			connectionsUrl,
			state.auth.token || '',
			'POST',
			{ ...<Payload>action.payload },
		);

		if (response.data.validation) {
			yield put(formValidationError(response.data.validation));
		} else {
			yield put(setExpanded(response.data.id));
			yield put(formReset());
			yield put(formModalHide());
			yield put(load());
		}
	} catch (e) {
		console.error(e);
	}
}

function* deleteC(action: Action) {
	const state: State = yield select();
	const { data } = <Payload>action.payload;

	try {
		yield authenticatedRequest(
			connectionsUrl + `${data.id}/`,
			state.auth.token || '',
			'DELETE',
		);

		yield put(load());
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e);
	}
}

export function* connectionsSaga(storeAPI: any) {
	yield takeLatest(LOAD, list);
	yield takeLatest(PAGINATE, list);
}

export function* connectionUpdateSaga(storeAPI: any) {
	yield takeLatest(UPDATE, update);
}

export function* connectionCreateSaga(storeAPI: any) {
	yield takeLatest(CREATE, create);
}

export function* connectionDeleteSaga(storeAPI: any) {
	yield takeLatest(DELETE, deleteC);
}

export function* setRouteTargetSaga(storeAPI: any) {
	yield takeLatest(SET_ROUTE_TARGET, setRouteTarget);
}
