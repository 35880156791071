// @ts-ignore
import watch from 'redux-watch';

import Pusher from 'pusher-js';

import { load as loadProfile } from './reducers/profile';
import { load as loadConnections } from './reducers/connections';
import { load as loadConnectionStats } from './reducers/connection_stats';
import { calculateResponsiveState } from 'redux-responsive';

export default (store: any) => {
	let authTokenWatcher = watch(store.getState, 'auth.token');

	if (typeof window != 'undefined') {
		store.dispatch(calculateResponsiveState(window));
	}

	store.subscribe(authTokenWatcher((newVal: any, oldVal: any, objectPath: any) => {
		//	token was filled, load the user profile
		if (newVal) {
			store.dispatch(loadProfile());
			store.dispatch(loadConnections());

			//	subscribe for pusher events
			pusherListener(newVal, store);
		}
	}));
};

interface PusherEventEmpty {

}

interface PusherEventPaymentCompleted {
	payment_id: number;
}

const pusherListener = (userToken: string, store: any) => {
	const pusher = new Pusher('b4fb281199406d4af628', {
		cluster : 'mt1',
		forceTLS: true,
	});

	const channel = pusher.subscribe(userToken);

	channel.bind('payment-completed', (data: PusherEventPaymentCompleted) => {
		store.dispatch(loadProfile());
	});

	channel.bind('subscription-created', (data: PusherEventEmpty) => {
		store.dispatch(loadProfile());
	});

	channel.bind('connections-updated', (data: PusherEventEmpty) => {
		if (window.location.pathname == '/app/dashboard') {
			//store.dispatch(loadConnections());
			store.dispatch(loadConnectionStats());
		}
	});
};
