// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blog-template-tsx": () => import("./../../../src/blog/template.tsx" /* webpackChunkName: "component---src-blog-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ad-blocker-tsx": () => import("./../../../src/pages/ad-blocker.tsx" /* webpackChunkName: "component---src-pages-ad-blocker-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-block-social-media-tsx": () => import("./../../../src/pages/block-social-media.tsx" /* webpackChunkName: "component---src-pages-block-social-media-tsx" */),
  "component---src-pages-dns-leak-test-tsx": () => import("./../../../src/pages/dns-leak-test.tsx" /* webpackChunkName: "component---src-pages-dns-leak-test-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-for-parents-tsx": () => import("./../../../src/pages/for-parents.tsx" /* webpackChunkName: "component---src-pages-for-parents-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internet-filter-office-tsx": () => import("./../../../src/pages/internet-filter-office.tsx" /* webpackChunkName: "component---src-pages-internet-filter-office-tsx" */),
  "component---src-pages-internet-filtering-schools-tsx": () => import("./../../../src/pages/internet-filtering-schools.tsx" /* webpackChunkName: "component---src-pages-internet-filtering-schools-tsx" */),
  "component---src-pages-porn-blocker-tsx": () => import("./../../../src/pages/porn-blocker.tsx" /* webpackChunkName: "component---src-pages-porn-blocker-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */)
}

