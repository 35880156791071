import { PaginatedFilteredSorted, PaginationPayload } from '../../types';
import { iModel as IModelRule } from './rules';
import { iModel as IModelDevice } from './device';
import { Model as IModelConnection } from './connection';
import { iModel as IModelQueryName } from './query_name';

export const logsPerPage = 30;

export interface iModel {
	[key: string]: string | number | IModelRule | IModelQueryName | IModelDevice | IModelConnection;

	id: number;
	rule: IModelRule;
	device: IModelDevice;
	type: number;
	qtype: string;
	qname: IModelQueryName;
	connection: IModelConnection;
	created: number;
	protocol: number;
	block_count: number;
}

export interface iData extends PaginatedFilteredSorted {
	loaded: boolean;
	results: iModel[];

}

export interface iState {
	data: iData;
}

export interface Payload {
	data: iModel;
	pagination: PaginationPayload;
}

export const initialState: iState = {
	data: {
		loaded    : false,
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loading   : false,
		filters   : [],
		sorters   : [],
		pageSize  : logsPerPage,
		pageNumber: 1,
	},
};
