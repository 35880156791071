import { initialState, iState, iPayload, iValidationErrors } from '../models/auth';

export const LOGOUT = 'AUTH/LOGOUT';
export const LOGIN = 'AUTH/LOGIN';
export const RESET = 'AUTH/RESET';
export const LOGGED_IN = 'AUTH/LOGGED_IN';
export const FORM_VALIDATION_ERROR = 'AUTH/VALIDATION_ERROR';


export interface Action {
	type: string;
	payload: iPayload;
}

export const logout = () => (
	{ type: LOGOUT }
);

export const reset = () => (
	{ type: RESET }
);

export const login = (username: string, password: string, redirectToDashboard: boolean) => (
	{ type: LOGIN, payload: { username, password, redirectToDashboard } }
);

export const loggedIn = (token: string) => (
	{ type: LOGGED_IN, payload: { token } }
);

export const formValidationErrors = (validation: iValidationErrors) => (
	{ type: FORM_VALIDATION_ERROR, payload: { validation } }
);

export default (state = initialState, action: Action): iState => {
	const { type, payload } = action;
	switch (type) {
		case LOGIN:
			return {
				...state, ...{
					formsubmitting: true,
				},
			};
		case LOGOUT:
		case RESET:
			return { ...state, ...initialState };
		case LOGGED_IN:
			return { ...state, token: payload.token };
		case FORM_VALIDATION_ERROR:
			return {
				...state, ...{
					formsubmitting  : false,
					validationErrors: Object.assign({}, state.validationErrors, {
						...payload.validation,
					}),
				},
			};
		default:
			return state;
	}
};

