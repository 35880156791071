import { iData, Payload, initialState, logsPerPage } from '../models/logs';
import { PaginationFilter, PaginationPayload } from '@/types';

export const LOAD = 'LOGS/LOAD';
export const LOADED = 'LOGS/LOADED';
export const FILTER = 'LOGS/FILTER';
export const PAGINATE = 'LOGS/PAGINATE';

export const FILTER_COLUMN_TYPE = 'type';
export const FILTER_COLUMN_DEVICE = 'device_name';
export const FILTER_COLUMN_QNAME = 'qname';
export const FILTER_COLUMN_CATEGORY = 'category';
export const FILTER_COLUMN_CONNECTION = 'connection';

export const FILTER_TYPE_ALL = 0;
export const FILTER_TYPE_BLOCKED = -1;
export const FILTER_TYPE_ALLOWED = 1;


export interface iAction {
	type: string;
	payload: Payload | PaginationFilter;
}

export const load = () => ({ type: LOAD });
export const paginate = (params?: PaginationPayload) => ({ type: PAGINATE, payload: { pagination: { ...params } } });
export const loaded = (data: iData) => ({ type: LOADED, payload: { data } });
export const filter = (field: PaginationFilter) => ({ type: FILTER, payload: field });

export default (state = initialState, action: iAction) => {
	const { type, payload } = action;
	const p = <Payload>payload;
	switch (type) {
		case FILTER:
			const fProp = <PaginationFilter>payload;
			let newFilters = state.data.filters.filter(e => e.column != fProp.column);
			if (fProp.query) newFilters.push({ column: fProp.column, query: fProp.query });

			if (fProp.column == FILTER_COLUMN_TYPE && fProp.query != `${FILTER_TYPE_BLOCKED}`) {
				// if there's a filter on block category we need to
				// reset it if we don't filter for blocked results
				newFilters = newFilters.filter(f => f.column != FILTER_COLUMN_CATEGORY);
			}

			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading   : true,
						filters   : newFilters,
						sorters   : state.data.sorters,
						pageSize  : state.data.pageSize || logsPerPage,
						pageNumber: 1,
					}),
				},
			};
		case PAGINATE:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading   : true,
						filters   : p.pagination.filters || state.data.filters,
						sorters   : p.pagination.sorters || state.data.sorters,
						pageSize  : p.pagination.pageSize || state.data.pageSize || logsPerPage,
						pageNumber: p.pagination.pageNumber || state.data.pageNumber || 1,
					}),
				},
			};
		case LOAD:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: true,
					}),
				},
			};
		case LOADED:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loaded : true,
						loading: false,
					}, p.data),
				},
			};
		default:
			return state;
	}
};

