import { PaginatedFilteredSorted, PaginationPayload } from '@/types';

export const resultsPerPage = 18;

export interface iModel {
	id: number;
	device_id: string;
	device_ip: string;
	device_name: string;
	device_model: string;
	allow_count: number;
	block_count: number;
	last_seen: number;
	connection: number;
}

export interface iData extends PaginatedFilteredSorted {
	results: iModel[];
}

export interface iState {
	data: iData;
	selected?: iModel;
	statsDrawerOpen?: boolean;
}

export interface iPayload {
	data: iModel | boolean;
	connectionId?: number;
	pagination: PaginationPayload;
}

export const initialState: iState = {
	data: {
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loaded    : false,
		loading   : false,
		filters   : [],
		sorters   : [],
		pageSize  : resultsPerPage,
		pageNumber: 1,
	},
};
