import { iModel as iPlanModel } from '../models/plans';
import { initialState, iState, iPayload } from '../models/payment';

export const SHOW = 'PAYMENT/SHOW';
export const CANCEL_CURRENT_SUBSCRIPTION = 'PAYMENT/CANCEL_CURRENT_SUBSCRIPTION';


export interface Action {
	type: string;
	payload: iPayload;
}

export const show = (plan: iPlanModel, cycle: 'monthly' | 'bi-annually' | 'annually') => (
	{ type: SHOW, payload: { plan, cycle } }
);

export const cancelCurrentSubscription = () => ({ type: CANCEL_CURRENT_SUBSCRIPTION });

export default (state = initialState, action: Action): iState => {
	const { type, payload } = action;
	switch (type) {
		default:
			return state;
	}
};

