import axios from 'axios';
import { navigate } from 'gatsby';
import { put, takeLatest } from 'redux-saga/effects';

import {
	Action,
	LOGIN,
	loggedIn,
	formValidationErrors,
} from '../reducers/auth';
import { load as loadProfile } from '../reducers/profile';
import { authUrl } from '@/routes';

function* auth(action: Action) {
	try {
		const response = yield axios.post(authUrl, {
			username: action.payload.username,
			password: action.payload.password,
		});

		//	persist to redux state
		if (response.data.token) {
			yield put(loggedIn(response.data.token));
			if (action.payload.redirectToDashboard) navigate('/app/dashboard');
			yield put(loadProfile());
		}

		if (response.data.validation) {
			yield put(formValidationErrors(response.data.validation));
		} else if (response.data.non_field_errors) {
			yield put(formValidationErrors({ username: response.data.validation[response.data.non_field_errors[0]] }));
		} else {
			yield put(loadProfile());
		}
	} catch (e) {
		yield put(formValidationErrors({ username: ['Invalid credentials'] }));
		console.error(e);
	}
}

export function* loginSaga(storeAPI: any) {
	yield takeLatest(LOGIN, auth);
}
