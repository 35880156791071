export default {
	'misc'       : {
		'More'    : 'More',
		'form'    : {
			'validation_age'     : 'Age must be between 1 and 18',
			'validation_required': 'This is a required field',
		},
		'blocked' : 'Blocked',
		'disabled': 'Disabled',
		'all'     : 'All',
	},
	'stats'      : {
		'timeline_title'        : 'Timeline stats',
		'monthly_usage'         : 'Monthly usage (%s)',
		'monthly_usage_desc'    : 'How much of your plan\'s limits has been consumed',
		'encrypted_queries'     : 'Encrypted queries (30 days)',
		'encrypted_queries_desc': 'Amount of queries carried over encrypted protocols (HTTPS, TLS)',
	},
	'devices'    : {
		'info'                : 'Devices are only reported when our software is running on a router',
		'title_none'          : 'No devices',
		'stats'               : {
			'total_requests'                : 'Total requests',
			'total_requests_blocked'        : 'Total requests blocked',
			'total_requests_blocked_tooltip': 'The number of requests that were blocked for this device',
			'total_requests_tooltip'        : 'The total number of requests performed by this device',
		},
		'no_connections'      : 'You have no connections',
		'select_configuration': 'Select configuration',
		'connection_map_title': 'Configuration map',
		'connection_map_info' : 'Map force this device to a specific configuration even if the device is connected via another configuration. When mapped, the device will obey the rules and settings of the mapped configuration.',
	},
	'schedule': {
		'about': 'From here you can re-route this configuration to another one that has different settings, based on a defined schedule. Outside the scheduled hours, the configuration will not be routed.',
		'configuration': 'Route target',
		'about_notice': 'Be informed that the DNS server to handle the requests, even when routed, will remain the same as specified in the original configuration',
		'interval_help': 'Select the hour intervals for each day when this routing is in effect. Outside the selected hours, the configuration will not be routed. To disable a day just overlap the two handles on the same (any) hour'
	},
	'logs'       : {
		'title'          : 'Logs',
		'title_disabled' : 'Logging is disabled',
		'info_disabled'  : 'Logging is disabled. If this is your first time on this page we want you to know that we value your privacy and we decided to disable logging by default. It\'s that type of service that we feel you have to opt-in for instead of opting-out.',
		'secondary_title': 'Activity logs',
		'enable'         : 'Enable logging',
		'disable'        : 'Disable logging',
		'subtitle'       : 'Detailed activity on your DNS traffic',
		'status_disabled': 'No logs are stored',
		'status_blocked' : 'Only blocked requests are stored',
		'status_all'     : 'All requests are stored',
		'rule_added'     : 'Done! Please allow up to 5 minutes for changes to take effect.',
		'cannot_add'     : 'I couldn\'t add rule',
		'cant_find_cat'  : 'I couldn\'t find a proper category',
	},
	'payments'   : {
		'plan_cancel_confirm_title'          : 'Are you sure you want to cancel your subscription?',
		'plan_cancel_confirm_body_subscribed': 'You will still be on this plan for the remainder of your subscription and downgraded to our free plan afterwards',
	},
	'connections': {
		'title'              : 'Your configurations',
		'alias_info'         : 'A friendly name for this connection so you can better recognize it and organize your experience.',
		'title_none'         : 'You have no configurations',
		'title_info'         : 'What is a configuration?',
		'config_id_copied'   : 'Config ID copied to clipboard',
		'server_copied'      : 'Server address copied to clipboard',
		'info'               : 'A configuration is a way of customizing your online experience. You can create multiple configurations, each with different settings, and use them separately across your devices',
		'create'             : 'Create one',
		'create_modal'       : 'Create configuration',
		'allowed_info'       : '{0} out of {1} allowed',
		'info_config_id'     : 'Use this config ID when setting up our DNS53 to DOH proxy software. More info on the Download page or in our Knowledgebase',
		'info_safe_search'   : 'When SafeSearch is on, it helps block explicit images, videos, and websites from search engine results. SafeSearch also disables comments and activates restricted mode on YouTube.',
		'info_lockdown'      : 'In lockdown mode all requests are denied except for those that are in your whitelist.',
		'info_age_restricted': 'Blocks all requests that have a higher age specified than the one attached to the querying connection.',
		'info_age_min'       : 'The age of the person using this connection. All hostnames marked with a higher age than this one will be blocked.',
		'info_fqdn'          : 'Use this value if you want to connect using the DOT (DNS over TLS) protocol.',
		'info_doh'           : 'Use this value if you want to resolve dns queries using the DOH protocol.',
		'info_allowed_ip'    : 'If you are connecting to your server using the traditional way on port 53 this is the allowed IP address to connect. If using our software, the authentication is handled automatically. Whenever your ip changes you must also update this field. For automatic update please see the options bellow.',
		'info_update_url'    : 'If your ip address is dynamic (changes often) you need to update the connection information as well.',
		'info_update_url2'   : 'You need to update only if using other connection methods besides DOH or if you\'re not using our software in which case, we do everything for you.',
		'dynamicdns_hostname': 'You can setup your router to use a dynamic DNS service such as DynDNS or NoIp in order to signal an IP change and update a hostname. We will query the hostname periodically and update the allowed ip address if we detect a change',
		'dot_info'           : 'DNS over TLS (DoT) is a security protocol for encrypting and wrapping Domain Name System (DNS) queries and answers via the Transport Layer Security (TLS) protocol. The goal of the method is to increase user privacy and security by preventing eavesdropping and manipulation of DNS data via man-in-the-middle attacks.',
		'dot_info_title'     : 'What does DOT stand for?',
		'doh_info_title'     : 'What does DOH stand for?',
		'doh_info'           : 'DNS over HTTPS (DoH) is a protocol for performing remote Domain Name System (DNS) resolution via the HTTPS protocol. A goal of the method is to increase user privacy and security by preventing eavesdropping and manipulation of DNS data by man-in-the-middle attacks by using the HTTPS protocol to encrypt the data between the DoH client and the DoH-based DNS resolver.',
		'block_groups_info'  : 'Use these settings to enable/disable the blocking of websites that fall under the selected category. A blue/selected setting means the websites will be blocked. Please keep in mind that a DNS cache may be in effect and the changes may take some time to propagate (between 1 and 10 minutes usually).',
		'block_groups_title' : 'What are block groups?',
		'form'               : {
			'alias'        : 'Alias',
			'alias_def'    : 'Enter a friendly name (ex: Home)',
			'age'          : 'Required min age',
			'age_def'      : 'If you want to impose age restrictions on this connection please specify the minimum age here. Leave 0 to disable age restrictions',
			'server'       : 'Server location',
			'server_def'   : 'Pick a DNS server location that will suit your needs. We recommend something close to your actual location',
			'server_select': 'Select a DNS server',
		},
		'config_grid'        : {
			'general_info': 'General information about your connection and the server that it is tied to',
			'block_gropus': 'Use these settings to allow or deny websites based on more than 10 groups available',
		},
	},
	'faq'        : {
		'title'            : 'Frequently asked questions',
		'lockdown_title'   : 'What is Lockdown mode?',
		'lockdown_desc'    : 'In lockdown mode all requests are denied except for the whitelisted ones. You can use this feature in schools where internet is supposed to be locked down during exams while allowing for some exceptions. Can also be used in situations where only a controlled list of domains are supposed to be allowed.',
		'parental_title'   : 'What Parental controls are supported?',
		'parental_desc'    : 'Allows you to add rules with age restrictions attached. A connection can be added with a specific age and all requests that do not satisfy the age requirement will be blocked. Can also be configured to allow browsing only within a specific time window - multiple such windows can be added.',
		'parental_desc_2'  : 'In the same category we also added the <b>SafeSearch</b> feature. When <b>SafeSearch</b> is on, it helps block explicit images, videos, and websites from search engine results by redirecting the requests to the safer variants.',
		'max_configs_title': 'What does the Max. configs represent?',
		'max_configs_desc' : 'The maximum number of devices allowed to connect to our DNS servers at the same time. A device can be a router providing internet for multiple devices or a single computer. It all depends where you configure the DNS servers.',
		'max_rules_title'  : 'What do the Max. rules represent?',
		'max_rules_desc'   : 'The maximum number of custom rules you can add. These rules represent both the <b>Block</b> &nbsp;rules and the <b>Whitelist</b> rules.',
		'limitations_title': 'Are there any limitations?',
		'limitations_desc' : 'Aside from the ones described in the pricing page we also enforce a limit on maximum requests per second <b>rps</b> &nbsp;that you may create. That limit is set at <b>30</b>/rps for each connection and it\'s designed to ensure that nobody can abuse our servers or their plan limits.',
		'trial_title'      : 'Is there a trial?',
		'trial_desc'       : 'Yes. The "Home" plan has a trial period of 10 days. If testing things out you should start with that plan and decide if our service is a good fit for your needs.',
		'ads_title'        : 'I still see some ads. What\'s the deal?',
		'ads_desc'         : 'It\'s impossible to block everything, especially at a DNS level, therefore we suggest using our DNS servers in tandem with a browser addon such as uBlock or AdBlock. Some ads don\'t load from different domains at all so we cannot block them from appearing.',
		'slow_title'       : 'Will it slow-down my internet?',
		'slow_desc'        : 'Since this is a DNS server it shouldn\'t really slow down your internet but there are a few things to keep in mind:',
		'slow_desc_1'      : 'Picking a DNS server which is far away from your location will slow down your internet a bit due to latency.',
		'slow_desc_2'      : 'Adding many custom rules can slow down our response speed.',
		'slow_desc_3'      : 'Enabling all blocking groups can also slow you down. In total, we have well over 2 Million domains blocked under our rules. Each request will have to be checked against that list. We have caching and other various techniques in place but still...',
		'unblock_title'    : 'Can I unblock a domain?',
		'unblock_desc'     : 'Sure! Just add a new rule that whitelists it and wait for the cache to propagate (usually in under 1h).',
	},
};

