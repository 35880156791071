import { iFormGeneric } from '@/types';
import { iModel } from './rules';


export interface iForm extends iFormGeneric {
	data: {
		rule: string;
		category?: number;
		connections?: number[];
		age_min: number;
	};
}

export interface iState {
	rule?: iModel;
	form: iForm;
	showModal?: boolean;
}

export interface iPayload {
	data: iModel;
	form: iForm;
}

export const initialState: iState = {
	form: {
		errors    : {},
		data      : {
			rule   : '',
			age_min: 0,
		},
		submitting: false,
	},
};
