import { initialState, iPayload } from '../models/rule';
import { iModel } from '../models/rules';

export const SELECT = 'RULE/SELECT';
export const UPDATE = 'RULE/UPDATE';
export const FORM_RESET = 'RULE/FORM_RESET';
export const FORM_CHANGE = 'RULE/FORM_CHANGE';
export const FORM_SUBMITTING = 'RULE/FORM_SUBMITTING';
export const FORM_VALIDATION_ERROR = 'RULE/FORM_VALIDATION_ERROR';

export interface Action {
	type: string;
	payload: iPayload;
}

export const select = (rule: iModel | null) => ({
	type: SELECT, payload: {
		data: rule,
	},
});

export const update = (data: iModel) => ({
	type: UPDATE, payload: {
		data,
	},
});

export const formSubmitting = () => (
	{ type: FORM_SUBMITTING }
);

export const formReset = () => (
	{ type: FORM_RESET }
);

export const formChange = (data: object) => (
	{ type: FORM_CHANGE, payload: { data } }
);

export const formValidationError = (errors: object) => (
	{ type: FORM_VALIDATION_ERROR, payload: { form: { errors } } }
);


export default (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case SELECT:
			return {
				...state, ...{
					rule     : payload.data,
					showModal: true,
				},
			};
		case FORM_SUBMITTING:
			return {
				...state, ...{
					formsubmitting: true,
				},
			};
		case FORM_CHANGE:
			return {
				...state, ...{
					form: Object.assign({}, state.form, {
						data: Object.assign({}, state.form.data, payload.data),
					}),
				},
			};
		case FORM_RESET:
			return {
				...state, ...{
					form: initialState.form,
				},
			};
		case FORM_VALIDATION_ERROR:
			return {
				...state, ...{
					formsubmitting: false,
					form          : Object.assign({}, state.form, {
						errors: Object.assign({}, state.form.errors, payload.form.errors),
					}),
				},
			};

		default:
			return state;
	}
};
