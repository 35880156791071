import { initialState, iState, iPayload } from '../../models/stats/protocols';

export const LOAD = 'STATS/PROTOCOLS/LOAD';
export const LOADED = 'STATS/PROTOCOLS/LOADED';


export interface iAction {
	type: string;
	payload: iPayload;
}

export const load = () => ({ type: LOAD });
export const loaded = (payload: iPayload) => ({ type: LOADED, payload });

export default (state = initialState, action: iAction): iState => {
	const { type, payload } = action;
	const p = <iPayload>payload;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					loading: true,
				},
			};
		case LOADED:
			return {
				...state, ...{
					loading: false,
					loaded : true,
					data   : p.data,
				},
			};
		default:
			return state;
	}
};

