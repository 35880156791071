import { Pagination, PaginationPayload } from '@/types';

export const resultsPerPage = 10;

export interface iModel {
	[key: string]: number | string | boolean;

	id: number;
	name: string;
	description: string;
	max_rps: number;
	max_rules: number;
	max_connections: number;
	max_monthly_queries: number;
	price_monthly: number;
	price_bi_annually: number;
	price_annually: number;
	supports_lockdown_mode: boolean;
}

export interface iData extends Pagination {
	results: iModel[];
}

export interface iState {
	data: iData;
}

export interface iPayload {
	data: iData;
	pagination: PaginationPayload;
}

export const initialState: iState = {
	data: {
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loading   : false,
		pageSize  : resultsPerPage,
		pageNumber: 1,
	},
};
