import { select, put, takeLatest } from 'redux-saga/effects';

import { LOAD, SET_INTERVAL, loaded, iAction } from '../reducers/connection_stats';
import { authenticatedRequest, SerializeParam, serialize } from '@/utils';

import { State } from '@/types';
import { connectionStatsUrl } from '@/routes';

function* _load(action: iAction) {
	const state: State = yield select();

	const queryObj: SerializeParam[] = [{
		key  : 'interval',
		value: state.connection_stats.interval,
	}];

	let url = connectionStatsUrl;
	if (state.connection_stats.connectionId) {
		url = `${url}/${state.connection_stats.connectionId}/`;
	}

	try {
		const response = yield authenticatedRequest(`${url}?${serialize(queryObj)}`,
			state.auth.token || '');
		yield put(loaded(response.data));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
	yield takeLatest(SET_INTERVAL, _load);
}
