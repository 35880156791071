import { select, put, takeLatest } from 'redux-saga/effects';

import { message } from 'antd';

import { iModel } from '@/state/models/device';
import { LOAD, PAGINATE, MAP2CONNECTION, load, loaded, iAction } from '@/state/reducers/devices';
import { authenticatedRequest, SerializeParam, serialize } from '@/utils';

import { State } from '@/types';
import { devicesUrl } from '@/routes';

function* _load(action: iAction) {
	const state: State = yield select();

	const queryObj: SerializeParam[] = [{
		key  : 'page',
		value: state.devices.data.pageNumber,
	}, {
		key  : 'page_size',
		value: state.devices.data.pageSize,
	}];

	if (state.devices.data.filters) {
		for (let i = 0; i < state.devices.data.filters.length; i++) {
			const filter = state.devices.data.filters[i];
			queryObj.push({
				key  : filter.column,
				value: filter.query,
			});
		}
	}

	if (state.devices.data.sorters) {
		for (let i = 0; i < state.devices.data.sorters.length; i++) {
			const sort = state.devices.data.sorters[i];
			queryObj.push({
				key  : 'ordering',
				value: `${sort.order == 'descend' ? '-' : ''}${sort.column}`,
			});
		}
	}

	try {
		const response = yield authenticatedRequest(`${devicesUrl}?${serialize(queryObj)}`,
			state.auth.token || '');
		yield put(loaded(response.data));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
	}
}

function* _map2Connection(action: iAction) {
	const state: State = yield select();

	try {
		const device = <iModel>action.payload.data;
		const response = yield authenticatedRequest(
			`${devicesUrl}${device.id}/`,
			state.auth.token || '',
			'PUT',
			{ connection__id: action.payload.connectionId },
		);

		message.success('Device was successfully mapped to connection');

		yield put(load());
	} catch (e) {
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
	yield takeLatest(PAGINATE, _load);
	yield takeLatest(MAP2CONNECTION, _map2Connection);
}
