import axios from 'axios';
import { select, put, takeLatest } from 'redux-saga/effects';

import { LOAD, Action, loaded } from '../reducers/servers';
import { State } from '@/types';
import { serversUrl } from '@/routes';

function* __load(action: Action) {
	const state: State = yield select();

	try {
		const response = yield axios.get(serversUrl);

		yield put(loaded(response.data));
	} catch (e) {
		console.error(e);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, __load);
}
