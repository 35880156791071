import { iData, iModel, iPayload, initialState } from '../models/device';
import { PaginationPayload } from '@/types';

export const LOAD = 'DEVICES/LOAD';
export const LOADED = 'DEVICES/LOADED';
export const SELECT = 'DEVICES/SELECT';
export const LOADING = 'DEVICES/LOADING';
export const PAGINATE = 'DEVICES/PAGINATE';
export const MAP2CONNECTION = 'DEVICES/MAP2CONNECTION';
export const TOGGLE_STATS_DRAWER = 'DEVICES/TOGGLE_STATS_DRAWER';

export interface iAction {
	type: string;
	payload: iPayload;
}

export const load = () => ({ type: LOAD });
export const loading = () => ({ type: LOADING });
export const loaded = (data: iData) => ({ type: LOADED, payload: { data } });
export const setSelected = (data: iModel) => ({ type: SELECT, payload: { data } });
export const map2Connection = (connectionId: number, data: iModel) => ({
	type   : MAP2CONNECTION,
	payload: { data, connectionId },
});
export const toggleStatsDrawer = (data: boolean) => ({ type: TOGGLE_STATS_DRAWER, payload: { data } });
export const paginate = (params: PaginationPayload) => ({ type: PAGINATE, payload: { pagination: { ...params } } });

export default (state = initialState, action: iAction) => {
	const { type, payload } = action;
	const p = <iPayload>payload;
	switch (type) {
		case PAGINATE:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						filters   : p.pagination.filters,
						sorters   : p.pagination.sorters,
						pageNumber: p.pagination.pageNumber,
					}),
				},
			};
		case LOAD:
		case LOADING:
			return {
				...state, ...{
					data: Object.assign(state.data, {
						loading: true,
					}),
				},
			};
		case LOADED:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: false,
						loaded : true,
					}, p.data),
				},
			};
		case SELECT:
			return {
				...state, ...{ selected: <iModel>p.data },
			};
		case TOGGLE_STATS_DRAWER:
			return {
				...state, ...{ statsDrawerOpen: <boolean>p.data || false },
			};
		default:
			return state;
	}
};

