export const LOAD = 'CONNECTION_STATS/LOAD';
export const LOADED = 'CONNECTION_STATS/LOADED';
export const SET_INTERVAL = 'CONNECTION_STATS/SET_INTERVAL';
export const LOAD_FOR_CONNECTION = 'CONNECTION_STATS/LOAD_FOR_CONNECTION';

export interface iAction {
	type: string;
	payload: iConnectionStat[] | number | string;
}

export interface iConnectionStat {
	allowed: number;
	blocked: number;
	timestamp: string;
}

export interface iState {
	results: iConnectionStat[];
	loading: boolean;
	interval: string;
	connectionId?: number;// showing for a single connection?
}

const initialState: iState = {
	loading : false,
	results : [],
	interval: '1-W',
};

export const load = () => (
	{ type: LOAD }
);

export const loaded = (results: iConnectionStat[]) => (
	{ type: LOADED, payload: results }
);

export const loadForConnection = (id: number) => (
	{ type: LOAD_FOR_CONNECTION, payload: id }
);

export const setInterval = (interval: string) => (
	{ type: SET_INTERVAL, payload: interval }
);

export default (state = initialState, action: iAction) => {
	const { type, payload } = action;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					loading: true,
				},
			};
		case LOADED:
			return {
				...state, ...{
					loading: false,
					results: <iConnectionStat[]>payload,
				},
			};
		case SET_INTERVAL:
			return {
				...state, ...{
					interval: <string>payload,
				},
			};
		case LOAD_FOR_CONNECTION:
			return {
				...state, ...{
					loading     : true,
					connectionId: <number>payload,
				},
			};
		default:
			return state;
	}
};

