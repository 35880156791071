import { select, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import {
	Action, formReset, LOAD, UPDATE, DELETE, load, loadSuccess, formValidationError,
} from '../reducers/profile';
import { authenticatedRequest } from '@/utils';

import { iFormValidationError, State } from '@/types';
import { profileUrl } from '@/routes';
import { logout } from '../reducers/auth';
import { iPayload } from '../models/profile';

function* _load(action: Action) {
	const state: State = yield select();

	try {
		const response = yield authenticatedRequest(profileUrl, state.auth.token || '');
		yield put(loadSuccess(response.data));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
		yield put(logout());
	}
}

function* _update(action: Action) {
	const state: State = yield select();
	const { payload } = action;

	try {
		const response = yield authenticatedRequest(profileUrl,
			state.auth.token || '',
			'PUT',
			{ ...(<iPayload>payload).data });

		if (response.data.validation) {
			const fields: any = Object.keys(response.data.validation);

			const vError: iFormValidationError = {};
			for (let i = 0; i < fields.length; i++) {
				vError[fields[i]] = response.data.validation[fields[i]];
			}

			yield put(formValidationError(vError));
		} else {
			message.success('Profile was updated', 3);

			yield put(load());
			yield put(formReset());
		}
	} catch (e) {
		console.error(e.message);
	}

}

function* _deleteProfile(action: Action) {
	const state: State = yield select();
	const { payload } = action;

	try {
		const response = yield authenticatedRequest(profileUrl,
			state.auth.token || '', 'DELETE');

		message.success('Your account was deleted!', 3);

		yield put(logout());
	} catch (e) {
		message.error('Error deleting your profile. Please contact us!', 5);
		console.error(e.message);
	}

}

export function* updateSaga(storeAPI: any) {
	yield takeLatest(UPDATE, _update);
}


export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
}


export function* deleteProfileSaga(storeAPI: any) {
	yield takeLatest(DELETE, _deleteProfile);
}
