import { select, put, takeLatest } from 'redux-saga/effects';

import { LOAD, FILTER, PAGINATE, loaded, iAction } from '../reducers/logs';
import { authenticatedRequest, SerializeParam, serialize } from '../../utils';

import { State } from '../../types';
import { queryLogsUrl } from '../../routes';

function* _load(action: iAction) {
	const state: State = yield select();

	const queryObj: SerializeParam[] = [{
		key  : 'page',
		value: state.logs.data.pageNumber,
	}, {
		key  : 'page_size',
		value: state.logs.data.pageSize,
	}];

	if (state.logs.data.filters) {
		for (let i = 0; i < state.logs.data.filters.length; i++) {
			const filter = state.logs.data.filters[i];
			queryObj.push({
				key  : filter.column,
				value: filter.query,
			});
		}
	}

	try {
		const response = yield authenticatedRequest(`${queryLogsUrl}?${serialize(queryObj)}`,
			state.auth.token || '');
		yield put(loaded(response.data));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
	yield takeLatest(FILTER, _load);
	yield takeLatest(PAGINATE, _load);
}
