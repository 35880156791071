import {
	Model,
	Data,
	Payload,
	initialState,
	ValidationErrors,
	RouteTargetModel,
	RouteValidationErrors,
} from '../models/connection';
import { PaginationPayload } from '@/types';

export const LOAD = 'CONNECTIONS/LOAD';
export const LOADED = 'CONNECTIONS/LOADED';
export const LOADING = 'CONNECTIONS/LOADING';
export const PAGINATE = 'CONNECTIONS/PAGINATE';

export const FORM_RESET = 'CONNECTIONS/FORM_RESET';
export const FORM_MODAL_SHOW = 'CONNECTIONS/FORM_MODAL_SHOW';
export const FORM_MODAL_HIDE = 'CONNECTIONS/FORM_MODAL_HIDE';

export const FORM_VALIDATION_ERRORS = 'CONNECTIONS/FORM_VALIDATION_ERRORS';
export const ROUTE_FORM_VALIDATION_ERRORS = 'CONNECTIONS/ROUTE_FORM_VALIDATION_ERRORS';

export const CREATE = 'CONNECTIONS/CREATE';
export const UPDATE = 'CONNECTIONS/UPDATE';
export const DELETE = 'CONNECTIONS/DELETE';

export const SET_EXPANDED = 'CONNECTIONS/SET_EXPANDED';
export const SET_ROUTE_TARGET = 'CONNECTIONS/SET_ROUTE_TARGET';


export interface Action {
	type: string;
	payload: Payload | number | RouteTargetModel;
}

export const formReset = () => ({ type: FORM_RESET });
export const load = () => ({ type: LOAD });
export const loading = () => ({ type: LOADING });
export const loaded = (data: Data) => ({ type: LOADED, payload: { data } });
export const paginate = (params: PaginationPayload) => ({ type: PAGINATE, payload: { pagination: { ...params } } });
export const formModalShow = () => ({ type: FORM_MODAL_SHOW });
export const formModalHide = () => ({ type: FORM_MODAL_HIDE });
export const create = (data: Model) => ({ type: CREATE, payload: { ...data } });
export const setExpanded = (id: number | undefined) => ({ type: SET_EXPANDED, payload: id });
export const update = (data: Model) => ({ type: UPDATE, payload: { data } });
export const deleteConnection = (data: Model) => ({ type: DELETE, payload: { data } });
export const setRouteTarget = (data: RouteTargetModel) => ({ type: SET_ROUTE_TARGET, payload: data });

export const formValidationError = (validationErrors: ValidationErrors) => (
	{ type: FORM_VALIDATION_ERRORS, payload: { validationErrors } }
);

export const routeFormValidationError = (routeValidationErrors: RouteValidationErrors) => (
	{ type: ROUTE_FORM_VALIDATION_ERRORS, payload: { routeValidationErrors } }
);

export default (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case PAGINATE:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						filters   : (<Payload>payload).pagination.filters,
						sorters   : (<Payload>payload).pagination.sorters,
						pageNumber: (<Payload>payload).pagination.pageNumber,
					}),
				},
			};
		case LOAD:
		case LOADING:
			return {
				...state, ...{
					data: Object.assign(state.data, {
						loading: true,
					}),
				},
			};
		case LOADED:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: false,
						loaded : true,
					}, (<Payload>payload).data),
				},
			};
		case UPDATE:
			return {
				...state, ...{
					data                 : Object.assign({
						loading: false,
					}, state.data),
					validationErrors     : {},
					routeValidationErrors: {},
				},
			};
		case FORM_MODAL_SHOW:
			return {
				...state, ...{
					formModalShow: true,
				},
			};
		case CREATE:
			return {
				...state, ...{
					validationErrors: {},
				},
			};
		case SET_EXPANDED:
			return {
				...state, ...{
					expandedConnection: <number>payload,
				},
			};
		case FORM_MODAL_HIDE:
			return {
				...state, ...{
					formModalShow: false,
				},
			};
		case FORM_VALIDATION_ERRORS:
			return {
				...state, ...{
					data            : Object.assign({
						loading: false,
					}, state.data),
					validationErrors: (<Payload>payload).validationErrors,
				},
			};
		case ROUTE_FORM_VALIDATION_ERRORS:
			return {
				...state, ...{
					data                 : Object.assign({
						loading: false,
					}, state.data),
					routeValidationErrors: (<Payload>payload).routeValidationErrors,
				},
			};
		case FORM_RESET:
			return {
				...state, ...{
					loading              : false,
					validationErrors     : initialState.validationErrors,
					routeValidationErrors: initialState.routeValidationErrors,
				},
			};
		default:
			return state;
	}
};

