import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createResponsiveStoreEnhancer } from 'redux-responsive';

import rootReducer from './reducers/index';
import rootSaga from './saga/index';
import watchers from './watchers';
import { load as loadPlans } from './reducers/plans';
import { load as loadRuleCategories } from './reducers/rule_categories';

const persistConfig = {
	key: 'dnsadblock-' + process.env.NODE_ENV,
	storage,
	//	auth needed for token storage
	//	connections needed for auto update of ip address
	whitelist: ['auth', 'connections'],
};

declare global {
	interface Window {
		clipboardData: any;
		Paddle: any;
		devToolsExtension: any;
		__REDUX_DEVTOOLS_EXTENSION__: any;
	}
}

const sagaMiddleware = createSagaMiddleware();

export default () => {
	let middleware = applyMiddleware(
		sagaMiddleware,
	);

	if (process.env.NODE_ENV !== 'production' && typeof window != 'undefined') {
		const devtools =
				process.env.NODE_ENV === 'development' && window.devToolsExtension
					? window.__REDUX_DEVTOOLS_EXTENSION__ &&
					window.__REDUX_DEVTOOLS_EXTENSION__()
					: (f: any) => f;
		middleware = compose(middleware, devtools);
	}

	middleware = compose(createResponsiveStoreEnhancer({ calculateInitialState: false }), middleware);

	const persistedReducer = persistReducer(persistConfig, rootReducer);
	const store = createStore(persistedReducer, middleware);
	const persistor = persistStore(store);

	const storeAPI = {
		subscribe: store.subscribe,
		dispatch : store.dispatch,
		getState : store.getState,
	};

	sagaMiddleware.run(rootSaga, storeAPI);

	watchers(store);

	store.dispatch(loadPlans());
	store.dispatch(loadRuleCategories());


	return { store, persistor };
};
