import { createSelector } from 'reselect';

import { State as iRootState } from '@/types';

const getConnections = (state: iRootState) => state.connections;

// The currently expanded connection (the one whose details are in view on the connections page)
export const getExpanded = createSelector(
	[getConnections],
	(connections) => {
		return connections.data.results.filter(c => c.id == connections.expandedConnection)[0];
	},
);
