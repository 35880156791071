import { select, put, takeLatest } from 'redux-saga/effects';

import { Action, UPDATE, formValidationError } from '../reducers/rule';
import { iFormValidationError, State } from '../../types';
import { userRulesUrl } from '../../routes';
import { authenticatedRequest } from '../../utils';
import { formReset, formSubmitting, select as selectRule } from '../reducers/rule';
import { load } from '../reducers/rules';

function* updateRule(action: Action) {
	const state: State = yield select();
	const selectedRule = state.rule.rule;

	try {
		yield put(formSubmitting());

		const response = yield authenticatedRequest(userRulesUrl + `${selectedRule?.id}/`,
			state.auth.token || '',
			'PUT',
			{
				...action.payload.data,
			});

		if (response.data.validation) {
			const fields: any = Object.keys(response.data.validation);

			const vError: iFormValidationError = {};
			for (let i = 0; i < fields.length; i++) {
				vError[fields[i]] = response.data.validation[fields[i]];
			}

			yield put(formValidationError(vError));
		} else {
			yield put(load());
			yield put(formReset());
			yield put(selectRule(null));
		}
	} catch (e) {
		yield put(formReset());
		yield put(load());

		console.error(e.message);
	}
}

export function* updateSaga(storeAPI: any) {
	yield takeLatest(UPDATE, updateRule);
}
