import { initialState, iState, iData, iPayload } from '../models/billing';

export const LOAD = 'BILLING/LOAD';
export const LOADED = 'BILLING/LOADED';
export const PAGINATE = 'BILLING/PAGINATE';


export interface Action {
	type: string;
	payload: iPayload;
}

export const load = () => (
	{ type: LOAD }
);

export const loaded = (data: iData) => (
	{ type: LOADED, payload: { data: data } }
);

export default (state = initialState, action: Action): iState => {
	const { type, payload } = action;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					data: Object.assign({
						loading: true,
					}, state.data),
				},
			};
		case LOADED:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: false,
					}, payload.data),
				},
			};
		case PAGINATE:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						filters   : action.payload.pagination.filters,
						sorters   : action.payload.pagination.sorters,
						pageNumber: action.payload.pagination.pageNumber,
					}),
				},
			};
		default:
			return state;
	}
};

