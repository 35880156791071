import { iModel as iPlanModel } from './plans';

export interface iState {
	plan: iPlanModel | null;
	cycle: 'monthly' | 'bi-annually' | 'annually';
}

export interface iPayload {
	plan: iPlanModel;
	cycle: 'monthly' | 'bi-annually' | 'annually';
}

export const initialState: iState = {
	plan : null,
	cycle: 'monthly',
};
