export interface iModel {
	total: number;
	allowed: number;
}

export interface iState {
	loaded: boolean;
	loading: boolean;
	data: iModel;
}

export interface iPayload {
	data: iModel;
}

export const initialState: iState = {
	loaded : false,
	loading: false,
	data   : {
		total  : 0,
		allowed: 0,
	},
};
