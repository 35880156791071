import axios, { AxiosRequestConfig } from 'axios';
import { iFormValidationError, iFormItemValidation } from './types';
import { message } from 'antd';

const parseDomain = require('parse-domain');

import translation from './locales/en-US/translation';
import { IBrowser } from 'redux-responsive';

// @ts-ignore
String.prototype.format = String.prototype.format || function() {
	// @ts-ignore
	let formatted = this;
	for (let arg in arguments) {
		formatted = formatted.replace('{' + arg + '}', arguments[arg]);
	}
	return formatted;
};

export const getTopDomain = (url: string): string => {
	const parsed = parseDomain(url);
	return parsed ? parsed.domain + '.' + parsed.tld : '';
};

export const getSubdomain = (url: string): string => {
	const parsed = parseDomain(url);
	return parsed ? parsed.subdomain : '';
};

export const selectCopyOnFocus = (event: any) => {
	event.preventDefault();
	event.target.select();
	document.execCommand('copy');
	message.success('Content was copied to clipboard');
};

/**
 * Creates requests that have the auth Token
 * automatically inserted
 */
export const authenticatedRequest = (
	url: string,
	token: string,
	method: string             = 'GET',
	data: any                  = {},
	config: AxiosRequestConfig = {},
) => {
	config.headers = Object.assign(config.headers || {}, {
		'Authorization': `Token ${token}`,
	});

	if (method == 'PUT') {
		return axios.put(url, data, config);
	}

	if (method == 'POST') {
		return axios.post(url, data, config);
	}

	if (method == 'DELETE') {
		return axios.delete(url, config);
	}

	return axios.get(url, config);
};

export const request = (
	url: string,
	method: string             = 'GET',
	data: any                  = {},
	config: AxiosRequestConfig = {},
) => {

	if (method == 'PUT') {
		return axios.put(url, data, config);
	}

	if (method == 'POST') {
		return axios.post(url, data, config);
	}

	if (method == 'DELETE') {
		return axios.delete(url, config);
	}

	return axios.get(url, config);
};

export interface SerializeParam {
	key: string;
	value: any;
}

export const openInNewTab = (url: string) => {
	const win = window.open(url, '_blank');
	win?.focus();
};

export const serialize = (params: SerializeParam[]) => params.map(
	param => `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`).join('&');


export const unixToDate = (unix: number): string => {
	const a = new Date(unix * 1000);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const year = a.getUTCFullYear();
	const month = months[a.getUTCMonth()];
	const date = a.getUTCDate();
	return date + '-' + month + '-' + year;
};

export const unixToDateTime = (unix: number): string => {
	const dateObj = new Date(unix * 1000);

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const year = dateObj.getUTCFullYear();
	const month = months[dateObj.getUTCMonth()];
	const day = dateObj.getUTCDate();

	// Get hours from the timestamp
	const hours = dateObj.getUTCHours();

	// Get minutes part from the timestamp
	const minutes = dateObj.getUTCMinutes();

	// Get seconds part from the timestamp
	const seconds = dateObj.getUTCSeconds();

	const d = `${month}/${day}/${year} ` + hours.toString().padStart(2, '0') + ':' +
		minutes.toString().padStart(2, '0') + ':' +
		seconds.toString().padStart(2, '0');
	const dutc = new Date(`${d} UTC`);
	return dutc.toLocaleDateString() + ' ' + dutc.toLocaleTimeString();
};

//	antd form values we receive are in a different form than the objects
//	we use to send data back to our servers so this method is fixing them
export const prepareFormValues = (values: any) => {
	const res: any = {};

	if (values.options) {
		for (let i = 0; i < values.options.length; i++) {
			res[values.options[i]] = true;
		}
	}

	delete values.options;

	return { ...res, ...values };
};

export const percentValFromTotal = (val: number, total: number): number => {
	return val * 100 / total;
};

//	helper for returning form field errors
//	validationErrors should be pulled from state (what we receive from server)
export const formFieldError = (field: string, validationErrors: iFormValidationError): iFormItemValidation => {
	if (
		!validationErrors ||
		!validationErrors.hasOwnProperty(field) ||
		!validationErrors[field] ||
		validationErrors[field] == undefined ||
		validationErrors[field]?.length == 0
	) return {};

	return {
		// @ts-ignore
		help          : validationErrors[field][0],
		hasFeedback   : true,
		validateStatus: 'error',
	};
};

export const t = (path: string): any => {
	return path.split('.').reduce((o: any, i) => {
		if (o) return o[i];
		console.warn(`I didn't find a translation for: ${path}`);
	}, translation);
};

export const ruleFavIcon = (rule: string): string => {
	let ruleFix = rule;
	if (ruleFix) ruleFix = getTopDomain(rule.replace('*', '').replace('||', ''));
	if (ruleFix == '') ruleFix = 'unknown.com';
	return `https://api.faviconkit.com/${ruleFix}/16`;
};

export const protocolStr = (proto: number) => {
	switch (proto) {
		case 1:
			return 'UDP';
		case 2:
			return 'TCP';
		case 3:
			return 'TLS';
		case 4:
			return 'HTTPS';
	}
	return '?';
};

export const fallbackCopyTextToClipboard = (text: string) => {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		document.execCommand('copy');
	} catch (err) {
		console.error('unable to copy to clipboard', err);
	}

	document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string) => {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	navigator.clipboard.writeText(text).catch(console.error);
};

export const ping = (url: string, timeout = 6000): Promise<number> => {
	return new Promise((reslove, reject) => {
		const urlRule = new RegExp('(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]');
		if (!urlRule.test(url)) reject('invalid url');
		try {
			const startDt = (new Date).getTime();
			fetch(url)
				.then(() => reslove((new Date).getTime() - startDt))
				.catch(() => reslove((new Date).getTime() - startDt));
		} catch (e) {
			reject(e);
		}
	});
};

export const showPhoneTablets = (browser: IBrowser, otherStyles: object = {}) => Object.assign(
	otherStyles,
	browser.lessThan.large ? { display: 'inherit' } : { display: 'none' },
);

export const hidePhoneTablets = (browser: IBrowser, otherStyles: object = {}) => Object.assign(
	otherStyles,
	browser.lessThan.large ? { display: 'none' } : { display: 'inherit' },
);
