import { iPayload, initialState, iData } from '../models/plans';

export const LOAD = 'PLANS/LOAD';
export const LOADED = 'PLANS/LOADED';

export interface Action {
	type: string;
	payload: iPayload;
}

export const load = () => (
	{ type: LOAD }
);

export const loaded = (data: iData) => (
	{ type: LOADED, payload: { data } }
);

export default (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: true,
					}),
				},
			};
		case LOADED:
			return {
				...state, ...{
					data: Object.assign(payload.data, {
						loading: false,
					}),
				},
			};

		default:
			return state;
	}
};

