export default {
	vendorId: 40180,
	products: {
		home      : {
			monthly      : 553216,
			'bi-annually': 553217,
			annually     : 553218,
		},
		office    : {
			monthly      : 553270,
			'bi-annually': 553271,
			annually     : 553272,
		},
		enterprise: {
			monthly      : 553273,
			'bi-annually': 553274,
			annually     : 553275,
		},
	},
};
