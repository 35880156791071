import { iModel, iPayload, initialState } from '../models/profile';

export const LOAD = 'PROFILE/LOAD';
export const UPDATE = 'PROFILE/UPDATE';
export const LOADED = 'PROFILE/LOADED';
export const FORM_RESET = 'PROFILE/FORM_RESET';
export const FORM_CHANGE = 'PROFILE/FORM_CHANGE';
export const FORM_VALIDATION_ERROR = 'PROFILE/FORM_VALIDATION_ERROR';
export const DELETE = 'PROFILE/DELETE';

export interface Action {
	type: string;
	payload: iPayload | number;
}

export const load = () => (
	{ type: LOAD }
);

export const update = (data: iModel) => ({ type: UPDATE, payload: { data } });

export const loadSuccess = (data: iModel) => ({ type: LOADED, payload: { data } });

export const deleteProfile = () => ({ type: DELETE });

export const formReset = () => ({ type: FORM_RESET });

export const formChange = (data: object) => (
	{ type: FORM_CHANGE, payload: { data } }
);

export const formValidationError = (errors: object) => (
	{ type: FORM_VALIDATION_ERROR, payload: { form: { errors } } }
);

export default (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: true,
					}),
				},
			};
		case LOADED:
			return { ...state, loaded: true, ...(<iPayload>payload) };
		case UPDATE:
			return {
				...state, ...{
					formsubmitting: true,
				},
			};
		case FORM_CHANGE:
			return {
				...state, ...{
					form: Object.assign({}, state.form, {
						data: Object.assign({}, state.form.data, (<iPayload>payload).data),
					}),
				},
			};
		case FORM_RESET:
			return {
				...state, ...{
					form: initialState.form,
				},
			};
		case FORM_VALIDATION_ERROR:
			return {
				...state, ...{
					formsubmitting: false,
					data          : Object.assign({
						loading: false,
					}, state.data),
					form          : Object.assign({}, state.form, {
						errors: Object.assign({}, state.form.errors, (<iPayload>payload).form.errors),
					}),
				},
			};
		default:
			return state;
	}
};

