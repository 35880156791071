import { Pagination, PaginationPayload } from '@/types';


export const resultsPerPage = 5;

export interface iModel {
	category: number;
	block_count: number;
}

export interface iState {
	data: iData;
}

export interface iData extends Pagination {
	results: iModel[];
}

export interface iPayload {
	data: iModel[];
	pagination: PaginationPayload;
}

export const initialState: iState = {
	data: {
		count     : 0,
		next      : '',
		previous  : '',
		results   : [],
		loaded    : false,
		loading   : false,
		pageSize  : resultsPerPage,
		pageNumber: 1,
	},
};
