import { select, put, takeLatest } from 'redux-saga/effects';

import { LOAD, loaded, iAction } from '../../reducers/stats/connections';
import { authenticatedRequest, serialize, SerializeParam } from '@/utils';

import { State } from '@/types';
import { statsConnections } from '@/routes';

function* _load(action: iAction) {
	const state: State = yield select();

	const queryObj: SerializeParam[] = [{
		key  : 'page',
		value: state.stats_connections.data.pageNumber,
	}, {
		key  : 'page_size',
		value: state.stats_connections.data.pageSize,
	}];

	try {
		const response = yield authenticatedRequest(`${statsConnections}?${serialize(queryObj)}`,
			state.auth.token || '');
		yield put(loaded(response));
	} catch (e) {
		//	No need to persist these exceptions
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
}
