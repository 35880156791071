import { all } from 'redux-saga/effects';

import { loginSaga } from './auth';
import { updateSaga as updateRuleSaga } from './rule';
import {
	loadSaga as loadRulesSaga,
	whitelistSaga as whitelistRuleSaga,
	createSaga as createRulesSaga,
	removeSaga as removeRuleRulesSaga,
} from './rules';

import { loadSaga as loadProfile, updateSaga as updateProfile, deleteProfileSaga as deleteProfile } from './profile';
import { loadSaga as loadPlans } from './plans';
import { registerSaga as register } from './register';
import { loadSaga as servers } from './servers';
import { showSaga as paymentShow, cancelCurrentSubscriptionSaga } from './payment';
import { loadSaga as loadBillingTransactions } from './billing';
import { loadSaga as loadLogs } from './logs';
import { loadSaga as loadRuleCategories } from './rule_categories';
import { loadSaga as loadConnectionStats } from './connection_stats';
import { loadSaga as loadDevices } from './devices';
import { loadSaga as loadDeviceStats } from './device_stats';
import { loadSaga as loadStatsProtocols } from './stats/protocols';
import { loadSaga as loadStatsConnections } from './stats/connections';
import { loadSaga as loadStatsCurrentUsage } from './stats/current_usage';
import { loadSaga as loadStatsRuleCategories } from './stats/rule_categories';
import {
	connectionsSaga,
	connectionUpdateSaga,
	connectionCreateSaga,
	connectionDeleteSaga,
	setRouteTargetSaga,
} from './connections';

export default function* rootSaga(storeAPI: any) {
	yield all([
		//	user & auth
		register(storeAPI),
		loginSaga(storeAPI),
		loadProfile(storeAPI),
		updateProfile(storeAPI),
		deleteProfile(storeAPI),

		//	servers
		servers(storeAPI),

		//	plans
		loadPlans(storeAPI),

		//	logs
		loadLogs(storeAPI),

		//	devices
		loadDevices(storeAPI),
		loadDeviceStats(storeAPI),

		//	rule categories
		loadRuleCategories(storeAPI),

		//	stats
		loadStatsProtocols(storeAPI),
		loadStatsConnections(storeAPI),
		loadStatsCurrentUsage(storeAPI),
		loadStatsRuleCategories(storeAPI),

		//	connection stats
		loadConnectionStats(storeAPI),

		//	payments
		paymentShow(storeAPI),
		cancelCurrentSubscriptionSaga(storeAPI),

		loadBillingTransactions(storeAPI),

		//	rule
		updateRuleSaga(storeAPI),

		//	rules
		loadRulesSaga(storeAPI),
		createRulesSaga(storeAPI),
		whitelistRuleSaga(storeAPI),
		removeRuleRulesSaga(storeAPI),

		//	connections
		connectionsSaga(storeAPI),
		connectionUpdateSaga(storeAPI),
		connectionCreateSaga(storeAPI),
		connectionDeleteSaga(storeAPI),
		setRouteTargetSaga(storeAPI),
	]);
}
