import { initialState, iState, iPayload } from '../../models/stats/connections';

export const LOAD = 'STATS/CONNECTIONS/LOAD';
export const LOADED = 'STATS/CONNECTIONS/LOADED';
export const PAGINATE = 'STATS/CONNECTIONS/PAGINATE';


export interface iAction {
	type: string;
	payload: iPayload;
}

export const load = () => ({ type: LOAD });
export const loaded = (payload: iPayload) => ({ type: LOADED, payload });

export default (state = initialState, action: iAction): iState => {
	const { type, payload } = action;
	const p = <iPayload>payload;
	switch (type) {
		case LOAD:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: true,
					}),
				},
			};
		case LOADED:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						loading: false,
						loaded : true,
					}, p.data),
				},
			};
		case PAGINATE:
			return {
				...state, ...{
					data: Object.assign({}, state.data, {
						filters   : p.pagination.filters,
						sorters   : p.pagination.sorters,
						pageNumber: p.pagination.pageNumber,
					}),
				},
			};
		default:
			return state;
	}
};

