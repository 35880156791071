import axios from 'axios';
import { select, put, takeLatest } from 'redux-saga/effects';

import { login } from '../reducers/auth';
import { REGISTER, Action, formValidationErrors, registered } from '../reducers/register';
import { State } from '../../types';
import { registerUrl } from '../../routes';

function* __register(action: Action) {
	const state: State = yield select();

	try {
		const response = yield axios.post(registerUrl, {
			email      : action.payload.email,
			username   : action.payload.username,
			password   : action.payload.password,
			password_re: action.payload.password_re,
		});

		if (response.data.success == true) {
			yield put(login(action.payload.username, action.payload.password, false));
		}

		if (response.data.validation) {
			yield put(formValidationErrors(response.data.validation));
		} else if (response.data.non_field_errors) {
			yield put(formValidationErrors({ username: response.data.validation[response.data.non_field_errors[0]] }));
		} else {
			yield put(registered());
		}
	} catch (e) {
		yield put(formValidationErrors({ username: ['Invalid data'] }));
		console.error(e);
	}
}

export function* registerSaga(storeAPI: any) {
	yield takeLatest(REGISTER, __register);
}
