// use xip otherwise csrf and access allow origin does not work
let baseUrl = 'http://localhost:8000';
if (process.env.NODE_ENV == 'production') {
	baseUrl = 'https://backend.dnsadblock.com';
}

baseUrl = 'https://backend.dnsadblock.com';

const url = (suffix: string) => baseUrl + suffix;

export const authUrl = url('/api-token-auth/');
export const readynessUrl = url('/_status/readiness/');
export const recoverPasswordUrl = url('/rest-auth/password/reset/');
export const createNewPasswordUrl = url('/api/auth/password/reset/confirm/');
export const plansUrl = url('/api/plans/');
export const ruleGroupsUrl = url('/api/rules/categories/');
export const serversUrl = url('/api/servers/available/');
export const registerUrl = url('/api/user/register/');
export const billingPaymentsUrl = url('/api/user/payments/');
export const subscriptionsUrl = url('/api/subscriptions/');
export const userRulesUrl = url('/api/user/rules/');
export const userRulesWhitelistedUrl = url('/api/user/rules/whitelisted/');
export const profileUrl = url('/api/user/profile/');
export const connectionsUrl = url('/api/user/connections/');
export const connectionRouteUrl = url('/api/user/connections/<%= connection_id %>/route/');
export const generalStatsUrl = url('/api/user/stats/categories/');
export const queryLogsUrl = url('/api/user/logs/');
export const devicesUrl = url('/api/user/devices/');
export const deviceTimelineStatsUrl = `${devicesUrl}<%= device_id %>/stats/timeline`;
export const connectionStatsUrl = url('/api/user/stats/general/');
export const myIpUrl = url('/myip/');
export const contactUrl = url('/contact/');

export const statsConnections = url('/api/user/stats/connections/');
export const statsProtocols = url('/api/user/stats/protocols/');
export const statsCurrentUsage = url('/api/user/stats/usage/');
export const statsRuleCategories = url('/api/user/stats/categories/');

export const knowledgebaseArticles = {
	homepage            : 'https://knowledgebase.dnsadblock.com/',
	dohHowTo            : 'https://knowledgebase.dnsadblock.com/what-is-doh-dns-over-https/',
	devicesHowTo        : 'https://knowledgebase.dnsadblock.com/what-are-network-devices/',
	allowedIpWhy        : 'https://knowledgebase.dnsadblock.com/why-do-i-need-to-link-my-ip-address-when-creating-a-connection/',
	updateDynamicIpHowto: 'https://knowledgebase.dnsadblock.com/how-to-update-your-connection-details-if-you-have-a-dynamic-ip-address/',
};
