import { put, takeLatest } from 'redux-saga/effects';

import { LOAD, loaded, Action } from '../reducers/rule_categories';
import { request } from '../../utils';
import { ruleGroupsUrl } from '../../routes';

function* _load(action: Action) {
	try {
		const response = yield request(ruleGroupsUrl);
		yield put(loaded(response.data));
	} catch (e) {
		console.error(e.message);
	}
}

export function* loadSaga(storeAPI: any) {
	yield takeLatest(LOAD, _load);
}
